import React, { Component }  from 'react';

// import './App.css';

import saveObjectToDatabase from "./saveObjectToDatabase.js"

class Saveobjectdata extends Component {

    componentDidMount = (props) => {
        console.log('mounted Saveobjectdata');
        console.log(props);
        saveObjectToDatabase(this.props.newdata, this.props.obj, this.props.db);
    }

render(props) {
    return (
        <React.Fragment>
            {JSON.parse(this.props.newdata)}
        </React.Fragment>
  );
}
}

export default Saveobjectdata;
