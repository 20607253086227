import React, { Component }  from 'react';

// import './App.css';

import saveObjectToDatabaseShallow from "./saveObjectToDatabaseShallow.js"

class Saveobjectdatashallow extends Component {

    componentDidMount = (props) => {
        console.log('mounted Saveobjectdatashallow');
        console.log(props);
        saveObjectToDatabaseShallow(this.props.newdata, this.props.obj, this.props.db);
    }

render(props) {
    return (
        <React.Fragment>
            {JSON.parse(this.props.newdata)}
        </React.Fragment>
  );
}
}

export default Saveobjectdatashallow;
