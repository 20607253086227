//  seis copyright 2017-2019
//  Desk.js
//  via mlBench & danmckeown.info
import React, { Component } from "react";

import Spreadsheet from "./Spreadsheet";

import Input from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';

import Headernav from "./Headernav";
import Footernav from "./Footernav";

import eqSet from "./eqset";

import reloadOnce from "./reloadOnce";

import runDBlookup from "./rundblookup";

import noTrueArraysman from "./notruearraysman";

import Textframe from "./textframe";

import Errorboundary from "./Errorboundary";

const loki = require("lokijs");
const convertObj = require("object-array-converter");

class Desk extends Component {
  state = {
    Ok: true,
    userObjectAsk: "",
    userDBrequest: "seisdb",
    dbdata: "-",
    dbdataArr: []
  };
  keyLibrary = new Set();
  lastOne = [];

  constructor() {
    super();

    this.handlecValueChange = this.handlecValueChange.bind(this);
    this.handleLookupButton = this.handleLookupButton.bind(this);
  }

  runDBlookup(dbOBJ, db = 'seisdb') {
    var apiDataDB = {};

    const AccountsDB = new loki(__dirname + "/db/" + db + ".json", {
      env: 'BROWSER'
    });

    const theParam = dbOBJ.toString();

    const that = this;
    AccountsDB.loadDatabase({}, function () {
      let _collection = AccountsDB.getCollection(theParam);

      if (!_collection) {
        console.log("Collection %s does not exist. Creating ... 🎮", theParam);
        _collection = AccountsDB.addCollection(theParam);
      } else {
        console.log("collection exists");
      }

      let retData = _collection.find();
      console.log(retData);

      apiDataDB = retData;

      let respObj = Object.assign({}, apiDataDB);
      let respArr = convertObj.toArray(respObj);

      that.setState({ dbdata: respArr, dbdataArr: respArr })
    });
  }

  goBack() {
    window.history.back();
  }

  pickSeis = () => {
    this.setState({ userObjectAsk: "seis" });
  }

  handlecValueChange(event) {
    let capturedVal = event.target.value;
    this.setState({ userObjectAsk: capturedVal });
  }

  handleDBvalChange = (event) => {
    let capturedVal = event.target.value;
    this.setState({ userDBrequest: capturedVal });
  }

  handleLookupButton(event) {
    let cont = runDBlookup(this.state.userObjectAsk, this.state.userDBrequest);
    const retCont = noTrueArraysman(cont);
    console.log(retCont);
    this.setState({ dbdataArr: noTrueArraysman(retCont) });
  }

  forEachDb(objArr) {
    for (let o of objArr) {
      let keyset = Object.keys(o);

      if (keyset !== this.keyLibrary.keys()) {
        for (let z of keyset) {
          this.keyLibrary.add(z);
        }
      }
    }
    return null;
  }

  forEachDbEntry(objArr) {
    for (let o of objArr) {
      let innerSet = new Set(Object.keys(o));
      let innerLibrary = new Set([...this.keyLibrary]);

      if (eqSet(innerSet, innerLibrary)) {
        console.log("matches keyLibrary");
      } else {
        console.log("fails to match keyLibrary");
      }

      let keyset = new Set(Object.keys(o));

      if (keyset !== this.keyLibrary.keys()) {
        for (let z of keyset) {
          this.keyLibrary.add(z);
        }
      }
    }
    return null;
  }

  componentDidMount = () => {
    if (this.props.userObjectAsk) {
      this.setState({ userObjectAsk: this.props.userObjectAsk })
    }
    if (this.props.userDBrequest) {
      this.setState({ userDBrequest: this.props.userDBrequest })
    }
  }

  render() {
    console.log("keyLibrary: " + this.keyLibrary);

    return (
      <React.Fragment>
        <Textframe />

        <div id="deskContainer" className="mlBench-content-wrappers">
          
          <button id="backButton" href="#" onClick={this.goBack}>
            ⬅️ back
        </button>

          <h1 id="desk">
            apple-picker Яecursive Object Desk<span id="rollLink">
              {" "}
              <a href="#" onClick={reloadOnce}>
                reload()
            </a>
            </span>
          </h1>

          <section id="dbInput">
            <section id="user-db-input">
              <span id="db-label">database: </span><Input id="user-db-name-input" onChange={this.handleDBvalChange} value={this.state.userDBrequest} />
            </section>
          </section>

          <aside>
            app database table: [<span id="clickSeis" onClick={this.pickSeis}>seis</span>]
          <br />
            <br />
          </aside>
          <section id="user-input">
            <Input
              id="crypto_output"
              onChange={this.handlecValueChange}
              value={this.state.userObjectAsk}
            />
            <button className="qw" onClick={this.handleLookupButton} id="lookupDB">
              lookup in DB
          </button>
          </section>
          <Card id="results">
            <Errorboundary>
              <Spreadsheet dbdataArr={this.state.dbdataArr} table={this.state.userObjectAsk} store={this.state.userDBrequest} />
            </Errorboundary>
          </Card>

          <Footernav />

          <style global>{`
          h1#desk, aside {
            font-family: Futura, "Ubuntu", "Lucida Grande", "Roboto", Helvetica,
              sans-serif;
          }
          footer#deskFooter {
            margin-top: 2em;
            font-family: Ubuntu, Roboto, Helvetica, sans-serif;
          }
          section#user-input {
            margin-bottom: calc(3vh + 10px);
          }
          #lookupDB {
            margin-left: calc(1vh + 10px);
          }
          #results {
            font-family: "Inconsolata", "Anonymous Pro", "Hack", Menlo, monospace;
          }
          div#deskContainer {
            background: #f7f8f9;
          }
          section#dbInput {
            margin-bottom: calc(3vh + 10px);
          }
          span#db-label {
            font-family: Courier, sans-serif;
          }
          span.valSheetRow {
            background-color: lightgray;
            margin-top: 12px;
            margin-right: 10px;
            margin-bottom: 0.6rem;
            line-height: 4.8;
            padding-left: calc(3px + 1vw);
            padding-right: calc(1px + 1vw);
            padding-top: calc(1px + 1.8vw);
            padding-bottom: calc(16px + 0.6vw);
          }
          span.valHeaderRow {
            background-color: lightgreen;
            margin-top: 12px;
            margin-right: 10px;
            margin-bottom: 0.6rem;
            line-height: 3.8;
            padding-left: calc(3px + 1vw);
            padding-right: calc(1px + 1vw);
            padding-top: calc(1px + 1.8vw);
            padding-bottom: calc(16px + 0.6vw);
          }
        `}</style>
        </div>
      </React.Fragment>
    );
  }
}

export default Desk;
