const loki = require("lokijs");
const convertObj = require("object-array-converter");
  
  export default function rundblookup(dbOBJ, db = 'accountsdb') {
    var apiDataDB = {};
 
    const AccountsDB = new loki("__dirname" + "/db/" + db + ".json", {
      env: 'BROWSER'
    });
    console.log(dbOBJ.toString());
    const theParam = dbOBJ.toString();

    const that = this;
    let retArr = new Array();
    AccountsDB.loadDatabase({}, function() {
      let _collection = AccountsDB.getCollection(theParam);

      if (!_collection) {
        console.log("Collection %s does not exist. Creating ... 🎮", theParam);
        _collection = AccountsDB.addCollection(theParam);
      } else {
        console.log("collection " + theParam + " exists");
      }

      let retData = _collection.find();
      console.log(retData);

      apiDataDB = retData;

      let respObj = Object.assign({}, apiDataDB);
      let respArr = convertObj.toArray(respObj);
      
      retArr = respArr;
    });
    return retArr;
  }
