import React from 'react';
import Headernav from "./Headernav";

function goBack() {
  window.history.back()
}

export default function About() {
  return (
    <React.Fragment>
      <Headernav />
      <button id="backButton" href="#" onClick={goBack}>
        ⬅️ back
        </button>
      <div>
        <pre>
        Roost
        <span> 💁🏻🦜</span>
        <span id="textBlockAboutLinks">
          <br className="spacer" />
          - a front-end API experiment scaffolded with <a href="https://github.com/facebook/create-react-app">create-react-app</a>
          <br className="spacer" />
          - copyright 2019 by <a href="http://danmckeown.info">Dan McKeown</a> and <a href="https://pacificio.com">pacificIO</a>
          <br className="spacer" />
          - attempting to implement the v1 API of <a href="https://github.com/pacificpelican/seis">Seis</a> + <a href="https://github.com/pacificpelican/OkConcept0">OkConcept0</a>
          <br className="spacer" />
          <br className="spacer" />
          - also includes a basic implementation of the <a href="https://github.com/pacificpelican/mlBench">`mlBench`</a> concept in <a href="../mlbrowser">Mlbrowser</a>
          <br className="spacer" />
          - the object entered can be used as training data
          <br className="spacer" />
          - once the model is trained it can offer results (currently: see console) based on input objects
          <br className="spacer" />
          - machine learning training and input data samples can be found on the brain.js <a href="https://github.com/BrainJS/brain.js">GitHub site</a>
        </span>
        </pre></div>
    </React.Fragment>
  )
}