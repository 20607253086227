const loki = require("lokijs");
const locatorScale = 10000000;

export default function saveobjecttodatabaseshallow (
    newdata = Object.assign({},{data: 'none', savedAt: 'null'}),
    objectTo = "notes",
    db = "accountsdb"
  ) {

    console.log("new data to be written");
    console.log(newdata);

    console.log("to Type:");
    console.log(objectTo);

    console.log("to DB:");
    console.log(db);

    const AccountsDB = new loki('__dirname' + "/db/" + db + ".json", {
      env: 'BROWSER'
    });
      
      const theParam = objectTo.toString();
      let newData = newdata;

      AccountsDB.loadDatabase({}, function() {
        let _collection = AccountsDB.getCollection(theParam);

        if (!_collection) {
          console.log(
            "Collection %s does not exist. Creating ... 🎮",
            theParam
          );
          _collection = AccountsDB.addCollection(theParam);
        } else {
          console.log("collection exists");
        }

        console.log("about to add tuple");
        console.log(typeof newData);
        console.log(newData);

        let dbObject = Object.assign(newData, {
          locator: Math.floor(Math.random() * locatorScale + 1),
          created_at_time: Date.now()
        });

        console.log("tuple to save");
        console.log(dbObject);

        _collection.insertOne(dbObject);

        console.log("saving to database: " + newData);
        AccountsDB.saveDatabase();

        let homeLink = "<a href='../../..'>Home</a>";
        console.log(Object.assign({}, { result: "record created" }));
      });
  }