//  Roost copyright 2017-2019
//  Objectcreator.js
//  via seis, apple-picker, mlBench, and danmckeown.info #5
import React, { Component } from "react";
import { TextInput, Button, Textarea, Pane } from "evergreen-ui";

import Headernav from "./Headernav";
import Errorboundary from "./Errorboundary";
import ReactJson from "react-json-view";
import reloadOnce from "./reloadOnce";
import Notepad from "./notepad";

class Objectcreator extends Component {
  state = {
    dataInput: '',
    dataInputProp1: '',
    dataInputProp2: '',
    dataInputVal1: '',
    dataInputVal2: '',
    dataDisplay: {},
    dataOutputProp1: '',
    dataOutputVal1: ''
  };

  goBack() {
    window.history.back();
  }

  handleinputval1Change = (event) => {
    let capturedVal = event.target.value;
    this.setState({ dataInputVal1: capturedVal });
  }

  handleinputprop1Change = (event) => {
    let capturedVal = event.target.value;
    this.setState({ dataInputProp1: capturedVal });
  }

  handleinputval2Change = (event) => {
    let capturedVal = event.target.value;
    this.setState({ dataInputVal2: capturedVal });
  }

  handleinputprop2Change = (event) => {
    let capturedVal = event.target.value;
    this.setState({ dataInputProp2: capturedVal });
  }

  handleoututprop1Change = (event) => {
    let capturedVal = event.target.value;
    this.setState({ dataOutputProp1: capturedVal });
  }

  handleoututval1Change = (event) => {
    let capturedVal = event.target.value;
    this.setState({ dataOutputVal1: capturedVal });
  }

  makeDataInput = (base = {}) => {
    let newObject = Object.assign({}, { input: { [this.state.dataInputProp1]: this.state.dataInputVal1, [this.state.dataInputProp2]: this.state.dataInputVal2 }, output: { [this.state.dataOutputProp1]: this.state.dataOutputVal1 } });
    console.log("new Object created: ");
    console.log(newObject.input);
    this.setState({ dataDisplay: newObject });
  }

  componentDidMount(props) {  //  Auto-fill the input fields if props are provided
    console.log("running componentDidMount");
    if (this.props.dataInput) {
      let newObj = this.props.dataInput;
      this.setState({ dataInput: newObj });
      console.log("setting initial input data via dataInput prop");
    }
    if (this.props.dataTable) {
      let newObj = this.props.dataTable;
      this.setState({ userObjectAsk: newObj });
    }
  }

  render() {
    return (
      <div id="ObjectCreatorContainer" className="mlBench-content-wrappers">
        <button id="backButton" href="#" onClick={this.goBack}>
          ⬅️ back
        </button>
        <Headernav />
        <h1 id="desk">
          <a href="https://pacificio.com">
            PacificIO
          </a> ObjCreator
          <span id="rollLink">
            {" "}
            <a href="#" onClick={reloadOnce}>
              reload()
            </a>
          </span>
        </h1>
        <section id="actual-user-input">
          <h5 id="input">Input</h5>
          prop 1: <TextInput
            type="string"
            id="inp_input_1"
            onChange={this.handleinputprop1Change}
            value={this.state.dataInputProp1}
          />
          val 1: <TextInput
            type="string"
            id="inp_val_1"
            onChange={this.handleinputval1Change}
            value={this.state.dataInputVal1}
          />
          <br className="space" />
          <br />
          prop 2: <TextInput
            type="string"
            id="inp_input_1"
            onChange={this.handleinputprop2Change}
            value={this.state.dataInputProp2}
          />
          val 2: <TextInput
            type="string"
            id="inp_val_1"
            onChange={this.handleinputval2Change}
            value={this.state.dataInputVal2}
          />
        </section>
        <section id="actual-user-output-input">
          <h5 id="input">Output</h5>
          prop 1: <TextInput
            type="string"
            id="inp_output_1"
            onChange={this.handleoututprop1Change}
            value={this.state.dataOututProp1}
          />
          val 1: <TextInput
            type="string"
            id="inp_val_1"
            onChange={this.handleoututval1Change}
            value={this.state.dataOutputVal1}
          />
        </section>
        <br />
        <section id="obj-user-input">
          <br />
          <Button onClick={this.makeDataInput}>
            create object
          </Button>
          <br />
          <br />
          <section id="spreadsheet">
            <Errorboundary>
              <ReactJson src={this.state.dataDisplay} />
            </Errorboundary>
          </section>
          <br />
          <br />
          <Notepad />
        </section>
        <style>{`
          div#ObjectCreatorContainer {
            font-family: Lato, Open Sans, Helvetica, Segoe UI, Ubuntu Sans, Roboto, sans-serif;
          }
        `}</style>
      </div>
    );
  }
}

export default Objectcreator;
