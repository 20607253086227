//  seis copyright 2017-2019
//  Headernav.js
//  via apple-picker, mlBench, and danmckeown.info #5
import React from 'react';

export default () => (
  <header id="topheader">
    <nav id="topnav">
      <ul id="navlist">
        <li id="homelink" key="home">
          <a href="../../../..">Home</a>
        </li>
        <li key="about">
          <a href="../../About">About</a>
        </li>
        <li key="mlbr">
          <a href="../../mlbrowser">Mlbrowser</a>
        </li>
        <li key="oc">
          <a href="../../create">ObjCreator</a>
        </li>
        <li key="oc">
          <a href="../../desk">ViewDesk</a>
        </li>
        <li key="roo">
          <a href="https://github.com/pacificpelican/roost">
            Code
          </a>
        </li>
      </ul>
    </nav>
    <style>{`
      header#topheader {
        font-family: "Inconsolata", "Anonymous Pro", "Hack", Menlo, monospace;
      }
      a,
      a:visited {
        color: black;
      }
      ul#navlist {
        display: flex;
      }
      ul#navlist li {
        margin-right: calc(10px + 3vw);
      }
    `}</style>
  </header>
);
