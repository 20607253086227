import React, { Component }  from 'react';
import ReactDOMServer from 'react-dom/server';

// import './App.css';

//  import saveObjectToDatabase from "./saveObjectToDatabase.js"

const Getdbdata = () => {

  return (
    `[{"ok":"yes"}]`
  );
}

export default Getdbdata;
