const loki = require("lokijs");
const locatorScale = 10000000;

export default function(
    newdata = "{data: 'none', savedAt: 'null'}",
    objectTo = "notes",
    db = "spiraldb"
  ) {

    console.log("new data to be written");
    console.log(newdata);

    const AccountsDB = new loki(__dirname + "/db/" + db + ".json", {
      env: 'BROWSER'
    });
      
      const theParam = objectTo.toString();
      let newData = newdata;

      AccountsDB.loadDatabase({}, function() {
        let _collection = AccountsDB.getCollection(theParam);

        if (!_collection) {
          console.log(
            "Collection %s does not exist. Creating ... 🎮",
            theParam
          );
          _collection = AccountsDB.addCollection(theParam);
        } else {
          console.log("collection exists");
        }

        console.log("about to add tuple");
        console.log(newData);
        let serverObject = JSON.parse(newData);
        // //  serverObject = JSON.parse(serverObject);
        // console.log(serverObject);

        let dbObject = Object.assign(serverObject, {
          locator: Math.floor(Math.random() * locatorScale + 1),
          created_at_time: Date.now()
        });

        console.log("tuple to save");
        console.log(dbObject);

        _collection.insertOne(dbObject);

        console.log("saving to database: " + newData);
        AccountsDB.saveDatabase();

        let homeLink = "<a href='../../..'>Home</a>";
        console.log(Object.assign({}, { result: "record created" }));
      });
  }