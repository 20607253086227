//  seis copyright 2017-2019
//  Footernav.js
//  via apple-picker, mlBench, and danmckeown.info #5
import React from 'react';

export default () => (
  <footer id="deskFooter">
    powered by{" "}
    <b>
      {" "}
      <a href="http://seis.pacificio.com">SEIS</a>{" via "}
      <a href="http://mlBench.pacificio.com">mlBench</a>{" "}
      <a href="https://bitbucket.org/pacificpelican/mlbench/src/master/">💻</a>{" "}/{" "}
      <a href="http://quarter.pacificio.com">Quarter Accounting App</a>{" "}
      <a href="https://bitbucket.org/pacificpelican/quarter/src/master/">
        💻
      </a>
    </b>
  </footer>
);
