//  Roost copyright 2017-2019
//  Mlbrowser.js
//  via seis, apple-picker, mlBench, and danmckeown.info #5
import React, { Component } from "react";
import { TextInput, Button, Pane } from "evergreen-ui";
import SpreadsheetObjectbrowser from "./SpreadsheetObjectbrowser";
import Headernav from "./Headernav";
import Errorboundary from "./Errorboundary";

import reloadOnce from "./reloadOnce";

import brain from "brain.js";

import saveObjectToDatabase from "./saveObjectToDatabaseShallow";

// provide optional config object (or undefined). Defaults shown.
const config = {
  binaryThresh: 0.5,
  hiddenLayers: [3],     // array of ints for the sizes of the hidden layers in the network
  activation: 'sigmoid',  // supported activation types: ['sigmoid', 'relu', 'leaky-relu', 'tanh'],
  leakyReluAlpha: 0.01   // supported for activation type 'leaky-relu'
}

function noTrueArraysMan(objArr) {
  let returnArr = [];
  for (let i = 0; i < objArr.length; i++) {
    returnArr.push(objArr[i]);
  }
  return returnArr;
}

//  const net = new brain.NeuralNetwork(config);

class Mlbrowser extends Component {
  state = {
    userObjectAsk: "seis",
    dbdata: "-",
    dbdataArr: [],
    dbdataArrState: [],
    indexURL: "",
    lastHeader: [],
    dbName: "seisdb",
    neural: {},
    userNet: []
  };
  keyLibrary = new Set();
  lastOne = [];
  constructor() {
    super();

    this.handlecValueChange = this.handlecValueChange.bind(this);
    this.handleuValueChange = this.handleuValueChange.bind(this);
    this.handleLookupButton = this.handleLookupButton.bind(this);
  }

  goBack() {
    window.history.back();
  }

  requestSaveToDatabase = () => {
    let filteredObject;

    filteredObject = this.state.dbdataArr;
  //  console.log(filteredObject.toString());
    console.log(this.state.userObjectAsk);
    console.log(this.state.dbName);
    console.log('typeof filteredObject');
    console.log(typeof filteredObject);
    saveObjectToDatabase(JSON.parse(filteredObject), this.state.userObjectAsk, this.state.dbName);
  };

  handlecValueChange(event) {
    let capturedVal = event.target.value;
    this.setState({ dbdataArr: capturedVal });
  }

  handleuValueChange(event) {
    let capturedVal = event.target.value;
    this.setState({ userNet: capturedVal });
  }

  handleLookupButton(event) {
    let capturedVal = this.state.dbdataArr;
    this.setState({ dbdataArrState: capturedVal });
  }

  handleDBNameChange = (event) => {
    let capturedVal = event.target.value;
    this.setState({ dbName: capturedVal });
  }

  pickSeis = () => {
    this.setState({ userObjectAsk: "seis" });
  }

  handleTABLEValueChange = (event) => {
    let capturedVal = event.target.value;
    this.setState({ userObjectAsk: capturedVal });
  }

  resetObject = () => {
    console.log('clearing object field')
    this.setState({ dbdataArr: `` })
  }

  resetN = () => {
    console.log('clearing object field')
    this.setState({ userNet: `` })
  }

  runNeuralNet = () => {
    //  train neural net
    const localnet = new brain.NeuralNetwork(config);

    let inpArray2 = [];
    inpArray2 = Array.from(JSON.parse(this.state.dbdataArr.replace(/ /g, '')));
    console.log('inpArray2 is a ' + typeof inpArray2);
    console.log("training with: " + [...noTrueArraysMan(inpArray2)]);
    localnet.train([...noTrueArraysMan(inpArray2)]);

    let inpArray = JSON.parse(this.state.userNet);
    console.log(inpArray);
    console.log(typeof inpArray);
    const result = localnet.run(inpArray);
    console.log("neural net test result: " + result);
    for (var e in result) {
      console.log(e.toString());
    }
    console.log(Object.keys(result));
    console.log(Object.values(result));
    return result;
  }

  componentDidMount(props) {  //  Auto-fill the input fields if props are provided to Objectbrowser
    console.log("running componentDidMount");
    if (this.props.dataArray) {
      let newObj = this.props.dataArray;
      this.setState({ dbdataArr: newObj });
      console.log("setting initial data");
    }
    if (this.props.dataTable) {
      let newObj = this.props.dataTable;
      this.setState({ userObjectAsk: newObj });
    }
    if (this.props.dataBase) {
      let newObj = this.props.dataBase;
      this.setState({ dbName: newObj });
    }
    if (this.props.dataNeural) {
      let newObj = this.props.dataNeural;
      this.setState({ userNet: newObj });
    }
  }

  render() {
    console.log("keyLibrary: " + this.keyLibrary);
    console.log("state.dbdataArrState: " + this.state.dbdataArrState);

    return (
      <div id="deskContainer" className="mlBench-content-wrappers">
        <button id="backButton" href="#" onClick={this.goBack}>
          ⬅️ back
        </button>
        <Headernav />
        <h1 id="desk">
          <a href="https://pacificio.com">
            PacificIO
          </a> Mlbrowser
          <span id="rollLink">
            {" "}
            <a href="#" onClick={reloadOnce}>
              reload()
            </a>
          </span>
        </h1>
        <h5>All objects must be enclosed by an array</h5>
        <section id="user-input">
          <span id="database">database: </span>
          <input
            type="string"
            id="obj_input_db"
            onChange={this.handleDBNameChange}
            value={this.state.dbName}
          />
          <br />
          <span id="database">table: </span>
          <input
            id="db_table"
            onChange={this.handleTABLEValueChange}
            value={this.state.userObjectAsk}
          />
          <br />
          <TextInput
            type="object"
            id="obj_input"
            onChange={this.handlecValueChange}
            value={this.state.dbdataArr}
          />
          <br />
          <Button appearance="primary" onClick={this.handleLookupButton} id="lookupDB">
            enter your JSON
          </Button> <Button intent="warning"  onClick={this.resetObject} id="resetObj">
            reset
          </Button>
        </section>
        <aside id="dbRequest">
          <Button id="btn-save" onClick={this.requestSaveToDatabase}>
            Save Object (🧪)
          </Button> <Button id="btn-save2" onClick={this.saveObjectToDatabase}>
            Save Object (📡)
          </Button>
          <br />
          <br />
          <span id="testData">test data: </span><TextInput
            type="object"
            id="obj_input"
            onChange={this.handleuValueChange}
            value={this.state.userNet}
          />
          <br />
          <Button appearance="primary" onClick={this.runNeuralNet} id="runneuralnet">
            run neural net
          </Button>
          {` `}<Button intent="warning" onClick={this.resetN} id="resetObj">
            reset
          </Button>
        </aside>
        <div id="objectCopy" className="dataReadout">
          <Pane>
            <details>
              <summary>object</summary>
              <p id="objReadout">{this.state.dbdataArrState}</p>
            </details>
          </Pane>
        </div>
        <Errorboundary>
          <SpreadsheetObjectbrowser dbdataArr={this.state.dbdataArrState} />
        </Errorboundary>
        <style>{`
          h1#desk {
            font-family: Futura, "Ubuntu", "Lucida Grande", "Roboto", Helvetica,
              sans-serif;
          }
          div#desk-wrapper.mlBench-content div#desk {
            width: 80vw;
            display: grid;
            grid-auto-columns: 75vw;
            grid-gap: 10px;
            grid-auto-rows: auto;
            background: azure;
          }
          section#datalibrary {
            display: flex;
            flex-flow: wrap;
            align-items: center;
          }
          span#database {
            font-family: Courier, sans-serif;
          }
          i.notColor {
            background: azure;
          }
          section#user-input, aside#dbRequest, Input {
            margin-top: calc(5px + 0.4vh);
            margin-bottom: calc(7px + 0.4vh);
          }
          input#obj_input_db, input#db_table {
            height: calc(1rem + 10px);
          }
        `}</style>
      </div>
    );
  }
}

export default Mlbrowser;
