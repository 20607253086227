import React from 'react';

// import './App.css';

function Api(props) {
  return (
    <React.Fragment>
     {props.children} 
    </React.Fragment>
  );
}

export default Api;
