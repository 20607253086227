import React, { Component } from "react";

//  textframe.js ReactJS 16.3+ component for apple-picker
//  by Dan McKeown http://danmckeown.info copyright 2018

import Homelink from "./homelink";
import newIMg from "./quarter-logo-mini.png";

export default class Textframe extends Component {
  reloadOnce() {
    console.log("about to reload");
    return window.location.reload();
    return false;
  }

  render(props) {
    return (
      <div id="ladder">
        <ul className="ladder-content">
          <li><span id="sym"><img id="qLogo" alt="Quarater Logo: 3 asymetric oval-shaped figures of different sizes" src={newIMg} ></img></span></li>
          {/* <li id="homelink"><Homelink /></li> */}
          <li id="file-link"><a onClick={this.reloadOnce} href="#">Refresh()</a></li>
          {/* <li id="file-link"><a href="/Objectbrowser">Objectbrowser</a></li>
          <li id="file-link"><a href="/Desk">Desk</a></li>
          <li id="file-link"><a href="/Spiral">Spiral</a></li>
          <li id="file-link"><a href="/Settings">Settings</a></li> */}
        </ul>
        <section id="content-section">

        </section>
        <style>{`
            div#ladder {
              height: calc(10px + 10vh);
            }
            div.pear-content {
              font-family: 'Hack', 'Fira Code', 'Menlo', monospace;
            }
            ul.ladder-content li {
              display: inline-block;
              padding-right: 1rem;
            }
            ul.ladder-content li {
              font-family: 'Hack', 'Fira Code', 'Menlo', monospace;
            }
            div#ladder {
              background: rgb(191,187,255);
              background: linear-gradient(90deg, rgba(191,187,255,0.7791317210477942) 0%, rgba(255,210,133,1) 64%, rgba(189,195,215,1) 74%);
              
              padding-bottom: calc(1ch);
              padding-left: calc(3vh + 10px);
              padding-right: calc(3vh + 10px);
              border-top-left-radius: calc(0.3rem);
            }
            a {
              color: inherit;
            }
            span#sym {
              font-size: calc(2.5rem);
            }
            img#qLogo {
              width: calc(4.9vh + + 1em + 12px);
              height: calc(2vw + 4px);
            }
            `}
        </style>
      </div>
    );
  }
}
