//  danmckeown.info #5 copyright 2017
//  notepad.js
import React, { Component } from 'react';
//  import Link from 'next';
//  import localforage from 'localforage';
  
class NotePadPage extends Component {
  constructor() {
    super();
    this.x = `<header id='topheader'>
      <span id="website"><a href='https://pacificio.com'>Get Your Web Site</a></li>
    </header>`;
    var localText = "";
    this.state = {value : localText};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    console.log('A note was submitted: ' + this.state.value);
    localStorage.setItem('usernote', this.state.value);
    event.preventDefault();
  }
  
  componentDidMount() {
    var value = localStorage.getItem('usernote');
    console.log('got locally stored item [in componentWillMount]: ' + value);
    this.setState({value:value});
  }

  render() {
    return (
    <div id="notepad" className="content">
      <div dangerouslySetInnerHTML={{ __html: this.x }}></div>
      <section id="notes_section">
        <h1>User Notes</h1>
        <span id="notes-note">Uses <a href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API">DOM storage</a> (which saves to the browser)</span>
          <div id="note">
              <form id="note_form" onSubmit={this.handleSubmit}>
              <textarea value={this.state.value} onChange={this.handleChange} id="userinput" />
              <button id="saver" onChange={this.saveInput}>
                  save
              </button>
              </form>
          </div>
      </section>
      <style jsx>{`
      header#topheader #website {
        background: #ffcaa3;
        font-family: Futura, "Lucdia Grande", "Ubuntu Sans", sans-serif;
        font-size: 0.75rem;
      }
    `}</style>
    </div>
    );
  }
}

export default NotePadPage
