import React from 'react';
import logo from './logo.svg';
import { Router, Link } from "@reach/router"

import Objectbrowser from "./Objectbrowser";
import Api from "./Api";
import Saveobjectdata from "./Saveobjectdata";
import Saveobjectdatashallow from "./Saveobjectdatashallow";
import Desk from "./Desk";
import Spiral from "./Spiral";
import Getdbdata from "./Getdbdata";
import About from "./About";

import Objectcreator from "./Objectcreator";
import Chess from "./Chess";

import './App.css';

let trainingData = `[
  {
     "input": {
        "r": 0.03,
        "g": 0.7,
        "b": 0.5
     },
     "output": {
        "black": 1
     }
  },
  {
     "input": {
        "r": 0.16,
        "g": 0.09,
        "b": 0.2
     },
     "output": {
        "white": 1
     }
  },
  {
     "input": {
        "r": 0.5,
        "g": 0.5,
        "b": 1
     },
     "output": {
        "white": 1
     }
  }
]`;

let neuralInp = `{
  "r": 1,
  "g": 0.4,
  "b": 0
}`;

function Home() {
  return (
    <div className="roostContainer">
      <h1 id="roost"><a href="../../..">Roost</a> 0️⃣•🕖•0️⃣{` `}<span>💁🏻🦜</span></h1>
      <Router>
        {/* <Objectbrowser dataArray={`[ { "val": "8" } ]`} path="/" /> */}
        <Objectbrowser dataArray={trainingData} dataNeural={neuralInp} path="/" />
        <Objectbrowser dataArray={trainingData} dataNeural={neuralInp} path="mlbrowser" />
        <Objectcreator path="/create" />
        <About path="/About" />
        <Chess path="chess" />
        <Desk path="desk" />
        <Spiral path="spiral" />
        <Api path="api">
          <Api path="1">
            <Api path="saveobjectdata">
              <Saveobjectdata path="db/:db/obj/:obj/newdata/:newdata">
                <Objectbrowser dataArray={`[ { "val": "5" } ]`} path="objectbrowser" />
              </Saveobjectdata>
            </Api>
            <Api path="saveobjectdatashallow">
              <Saveobjectdatashallow path="db/:db/obj/:obj/newdata/:newdata">
              </Saveobjectdatashallow>
            </Api>
            <Api path="getdbdata">
              <Getdbdata path="db/:db/object/:obj">
              </Getdbdata>
            </Api>
          </Api>
        </Api>
      </Router>
      <style>
        {`
          div.roostContainer {
            padding-inline-start: calc(2vw + 2px);
          }
          h1#roost a {
            text-decoration: none;
            color: black;
          }
        `}
      </style>
    </div>
  );
}

export default Home;