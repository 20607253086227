//  seis copyright 2017-2019
//  SpreadsheetObjectbrowser.js
//  via mlBench & danmckeown.info
import React, { Component } from "react";

class piece {
  positionX = 1;
  positionY = 1;
  constructor(name, moveForward, moveLeft, moveRight, moveBackward, id) {
    this.id = id;
    this.name = name;
    this.moveForward = moveForward;
    this.moveBackward = moveBackward;
    this.moveLeft = moveLeft;
    this.moveRight = moveRight
  }
  getPosition = () => {
    console.log(this.positionX, this.positionY);
    return [this.positionX, this.positionY];
  }
  movePieceVertical = (direction, spaces) => {
    if (direction === 'up') {
      this.positionX = this.positionX + spaces;
    }
    else if (direction === 'up') {
      this.positionX = this.positionX - spaces;
    }
  }
  movePieceHorizontal = (direction, spaces) => {
    if (direction === 'right') {
      this.positionY = this.positionY + spaces;
    }
    else if (direction === 'left') {
      this.positionY = this.positionY - spaces;
    }
  }
}

class SpreadsheetObjectbrowser extends Component {
  state = { piece1: new piece(), nextMove : ['up', 2] }

  constructor() {
    super();
  }
  

  goBack() {
    window.history.back();
  }

  getapieceposition = () => {
    // console.log("trying to get new piece");
    // let x = new piece();
    console.log(this.state.piece1);
    console.log(this.state.piece1.getPosition());
  }

  movepiecevertical = () => {
    console.log("running movepiecevertical");
    this.state.piece1.movePieceVertical(...this.state.nextMove);
  }

  render(props) {
    

    return (
      <div id="desk-wrapper" className="mlBench-content">
       <span onClick={this.getapieceposition} id="piece">get positions</span><br /><br /><br />
       <span onClick={this.movepiecevertical} id="piece">move up</span>
       {/* <span onClick={this.getapiece} id="piece">init</span> */}
        <style>{`
       
        `}</style>
      </div>
    );
  }
}

export default SpreadsheetObjectbrowser;
